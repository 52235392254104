import React, { useCallback, useState, useEffect } from 'react';
import Icon from "../../atoms/Icon/Icon"

import styles from './ScrollToTop.module.css';

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.scrollY > 200 && !showButton) {
            setShowButton(true);
        } else if (window.scrollY <= 200 && showButton) {
            setShowButton(false);
        }
    }, [showButton, setShowButton])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [showButton, handleScroll]);

    const onClickEvent = (e) => {
        e.preventDefault();
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    }

    return (
        <>
        {showButton && (
            <span className={styles.scrollTop} 
                onClick={onClickEvent} 
                role="presentation">
                <Icon symbol="arrowDown" />
            </span>
        )}
        </>
    );
};

export default ScrollToTop;
