import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../atoms/Button/Button'

const ConfirmDialog = ({children, title, message, onOk, onCancel, btnOk, btnCancel}) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        }
        setOpen(false)
    }

    const handleOk = () => {
        if (onOk) {
            onOk()
        }
        setOpen(false)
    }

    return (
        <>
            <span role="presentation" onClick={handleClick}>{children}</span>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                keepMounted
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={open}
                >
                <DialogTitle id="confirmation-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} level="secondary" size="small" type="span">
                    {btnCancel || 'Cancel'}
                    </Button>
                    <Button onClick={handleOk} level="primary" size="small" type="span">
                    {btnOk || 'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDialog