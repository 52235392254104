import React, { useEffect, useContext, useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import CartContext from '../../../context/CartProvider';
import styles from './CartSummary.module.css';
import Icon from '../../atoms/Icon/Icon';

import Checkbox from '../../atoms/Checkbox/Checkbox';
import Loader from '../../atoms/Loader/Loader';

const Donation = () => {
    const ctx = useContext(CartContext);
    const cart = get(ctx, 'state.cart', {});
    const { currency } = cart;
    const donationProduct = get(ctx, 'state.donationProduct', false);
    const donationMessage = get(ctx, 'state.donationMessage', false);
    const donationLabel = get(ctx, 'state.donationLabel', false);
    const donationMoreInfo = get(ctx, 'state.donationMoreInfo', false);
    const [donationImage, setDonationImage] = useState(null);
    const donationAdded = get(ctx, 'state.donationAdded', false);
    const donationAddedVariant = get(ctx, 'state.donationAddedVariant', false);
    const donationAddedImage = get(ctx, 'state.donationAddedImage', false);
    const donationAddedAmount = get(ctx, 'state.donationAddedAmount', false);
    const addDonation = ctx && ctx.addDonation;
    const removeDonation = ctx && ctx.removeDonation;
    const [workingDonation, setWorkingDonation] = useState(false);
    const [donationVariant, setDonationVariant] = useState(false);
    const [donationAmount, setDonationAmount] = useState(false);

  useEffect(() => {
    if (donationAdded && donationAddedVariant && donationAddedAmount) {
      setDonationVariant(donationAddedVariant);
      setDonationImage(donationAddedImage);
      setDonationAmount(donationAddedAmount);
    } else if (!donationVariant && donationProduct.variants && donationProduct.variants.length > 1) {
      setDonationVariant(donationProduct.variants[1].id);
      setDonationImage(donationProduct.variants[1].image_url);
      setDonationAmount(false);
    } else if (!donationVariant && donationProduct.variants && donationProduct.variants.length === 1) {
      setDonationVariant(donationProduct.variants[0].id);
      setDonationImage(donationProduct.variants[0].image_url);
      setDonationAmount(false);
    }

    if (donationProduct && !donationVariant && (!donationAdded || !donationAddedVariant || !donationAddedAmount)) {
      const optionValues = donationProduct.options.map(option => option.option_values)[0];
      const defaultOptionValue = optionValues.find(optionValue => optionValue.is_default);
      const defaultOptionId = defaultOptionValue.id;

      const defaultVariantObj = donationProduct.variants.find(variant => variant.option_values.find(optionValue => optionValue.id === defaultOptionId));
      const defaultVariantId = defaultVariantObj.id;

      setDonationVariant(defaultVariantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationAdded, donationAddedVariant, donationAddedAmount, donationVariant, donationAddedImage, donationProduct.variants]);

  const triggerDonationUI = () => {
    setWorkingDonation(true);
    addDonation(donationProduct.id, donationVariant).then(() => {
      setWorkingDonation(false);
    })
  }

  const resetDonationCart = (variantId) => {
    setDonationVariant(variantId);

    if (donationAdded) {
      setWorkingDonation(true);
      removeDonation(donationProduct.id, variantId).then(() => {
        setDonationVariant(variantId);
        setWorkingDonation(false);
      })
    }
  }

  return (
    <>
        <div className={`${styles.bcCartSummaryItem}`}>

            <div className={clsx('flex-between', 'flex-top')}>
            <div className={styles.bcCartSummaryItemLabel}>
            <div className={styles.bcCartDonation}>
                <div className={styles.cartDonationMessage}>
                    {donationMessage}
                </div>
                <div className={styles.cartDonationImage}>
                    <img loading="lazy" src={donationImage} alt={`${donationLabel}`} />
                </div>


                    {donationProduct.variants.length === 1 && (
                    <div className={styles.noVariantTitle}>
                        {donationProduct.name}
                        {/* - <strong><CurrencyFormatter
                        currency={currency.code}
                        amount={parseFloat(donationProduct.sale_price)}
                        ignoreTax={true} /></strong> */}
                    </div>
                    )}
                    {donationProduct.variants.length > 1 && (
                    <div className={styles.variantOptionsWrap}>
                    <select
                        className={styles.variantOptionsSelect}
                        value={donationVariant}
                        onChange={e => resetDonationCart(e.target.value)} 
                        onBlur={() => {}}
                        > 
                        {donationProduct.variants.map((variant, variantIndex) => (
                        <option value={variant.id} key={variantIndex}>{variant.option_values.map(option => option.label).join(' ')}</option>
                        ))}
                    </select>
                    <Icon symbol='arrowDown' />
                    </div>)}
                </div>

                <Checkbox key={`donation-${donationAdded}`} isChecked={donationAdded} action={triggerDonationUI} label={donationLabel} />
                {donationMoreInfo && (
                <div className={styles.donationMoreInfo} dangerouslySetInnerHTML={{__html: donationMoreInfo}}></div>
                )}

            </div>


            <span className={`${styles.bcCartSummaryItemValue} ${styles.donationItemValue}`}>
                {donationAmount && donationAdded && (
                <CurrencyFormatter
                currency={currency.code}
                amount={parseFloat(donationAmount)}
                ignoreTax={true} />
                )}
            </span>

            </div>

            {workingDonation && (<span className={styles.loader}><Loader /></span>)}
        </div>
    </>
  );
};

export default Donation;
