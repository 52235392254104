import React, { useState, useEffect, useMemo } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import styles from './Footer.module.css';

import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia';

import ImagineThat from '../../../assets/images/logo_imaginethat.svg';
import Awesome from '../../../assets/images/logo_abetterawesome.svg';
import Logo from '../../atoms/Logo/Logo';

import Divider from '../../atoms/Divider/Divider';
import Container from '../../atoms/Container/Container';
import SubscribeForm from '../../molecules/SubscribeForm/SubscribeForm';
import FooterList from '../../molecules/Footer/FooterList';

import ScrollToTop from '../../molecules/ScrollToTop/ScrollToTop';
import PaymentMethods from '../../molecules/PaymentMethods/PaymentMethods';

const Footer = () => {
  const [navStatus, setNavStatus] = useState({});
  const [retailMenu, setRetailMenu] = useState([]);
  const [blogMenu, setBlogMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState([]);

  const menuData = useStaticQuery(graphql`
    query {
      allBuilderModels {
        menus(options: { cachebust: true }) {
          name
          data {
            menuItems
          }
        }
      }
      allWpCategory {
        nodes {
          uri
          name
        }
      }
    }
  `);

  useEffect(() => {
    setActiveMenu(retailMenu);
  }, [retailMenu])

  const outputMenu = (menuItems) => {
    const handleClick = idSelected => {
      const newStatus = navStatus;
      if (idSelected in newStatus) {
        // Remove option
        delete newStatus[idSelected];
      } else {
        newStatus[idSelected] = true;
      }
  
      setNavStatus({ ...newStatus });
    };

    return (
      <>
      {menuItems &&
        menuItems.map((nav, navId) => (
          <FooterList
            key={navId}
            nav={nav}
            isOpen={navStatus[nav.menuLabel] ? true : false}
            handleClick={handleClick}
          />
        ))}
      </>
    );
  };

  const menuFooter = () => {
    return (
      <>
        <ScrollToTop />
        <footer className={styles.footer}>
          <Container>
            <Divider shape="teeth" />

            <div className={`${styles.footerListRow}`}>
              <SubscribeForm />
            </div>

            <div
              className={`flex-center ${styles.footerListRow} ${styles.footerRowAwesome}`}
            >
              {Awesome && <Awesome />}
              <ul className={`flex-middle ${styles.awesomeList}`}>
                {blogMenu &&
                  blogMenu.slice(0, 3).map((item, index) => (
                    <li
                      className={`mb-0 ${styles.awesomeListItem}`}
                      index={index}
                      key={index}
                    >
                      <a href={item.uri}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>

            <div className={`flex-between ${styles.footerWrapper}`}>
              <div className={`${styles.footerListStyle} ${styles.footerLogo}`}>
                <Logo siteTitle="Logo" />
                {ImagineThat && <ImagineThat className="mt-2" />}
              </div>

              <div className={`flex-between ${styles.footerNavWrapper}`}>
                {outputMenu(activeMenu)}
              </div>

              <div className={styles.footerListStyle}>
                <p className={`small ${styles.footerListHeading}`}>Follow Us</p>
                <div className={styles.socials}>
                  <NavSocialMedia />
                  <PaymentMethods />
                </div>
              </div>
            </div>

            <div
              className={`flex-between ${styles.footerListRow} ${styles.footerListBottom}`}
            >
              <p
                className={`${styles.footerLinksBottom} ${styles.footerBottomMobile}`}
              >
                &copy; {new Date().getFullYear()} Spencil - All Rights Reserved
              </p>
              <ul
                className={`row ${styles.awesomeList} ${styles.footerBottomMobile}`}
              >
                {/* <li className={styles.footerLinksBottom}>
                  <Link className={styles.footerListItem} to="/sitemap/">
                    Site Map
                  </Link>
                </li> */}
                <li className={styles.footerLinksBottom}>
                  <Link className={styles.footerListItem} to="/privacy-policy/">
                    Privacy Policy
                  </Link>
                </li>
                <li className={styles.footerLinksBottom}>
                  <Link className={styles.footerListItem} to="/terms-conditions/">
                    Terms &#38; Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </Container>
        </footer>
      </>
    );
  };

  useMemo(() => {
    if (activeMenu.length === 0) {
      menuData?.allBuilderModels.menus.map(menu => {
        if (menu.name === 'Footer') {
          const retailMenuItems = menu.data.menuItems;
          setRetailMenu(retailMenuItems);
        }
        return true;
      });
    }

    const awesomeMenu = menuData?.allWpCategory.nodes;
    const awesomeItems = awesomeMenu?.map(item => ({
      uri: item.uri ? `/abetterkinderawesome${item.uri}` : '',
      label: item.name
    }));
    setBlogMenu(awesomeItems);
  }, [activeMenu, menuData])

  return menuFooter();
};

export default Footer;
