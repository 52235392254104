import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import CartContext from '../../../context/CartProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Button from '../../atoms/Button/Button';
import styles from './CartSummary.module.css';
import Dialog from "../../atoms/Dialog/Dialog"

const CouponForm = () => {
  const {
    state: {
      coupons,
      cart: { currency }
    },
    addCoupons,
    removeCoupons
  } = useContext(CartContext);

  const [couponcode, setCouponcode] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);

  const onClickToggle = () => {
    setShowForm(!showForm);
  };

  const onChangeValue = e => {
    const { value } = e.target;
    setCouponcode(value);
  };

  const onSubmitForm = () => {
    addCoupons(couponcode).catch(response => {
      // console.log("Error: ", response);
      setDialogMessage(response.title);
    });
  };

  const handleRemovecCoupon = couponcode => () => {
    removeCoupons(couponcode);
  };

  const clearDialog = () => {
    setDialogMessage(false);
  }

  return (
    <>
      {isEmpty(coupons) ? (
        <div className="flex-between">
          <span className={styles.bcCartSummaryItemLabel}>Coupon Code</span>
          <span
            data-button
            role="presentation"
            onClick={onClickToggle}
            className={styles.bcCartSummaryToggle}
          >
            {showForm ? 'Cancel' : 'Add Coupon'}
          </span>
        </div>
      ) : (
        coupons.map(coupon => (
          <div className="flex-between" key={coupon.code}>
            <div className={styles.bcCartSummaryItemLabel}>
              <div>{coupon.name || `Coupon (${coupon.code})`}</div>
              <span
                data-button
                role="presentation"
                onClick={handleRemovecCoupon(coupon.code)}
                className={styles.bcCartSummaryToggle}
              >
                Remove
              </span>
            </div>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                currency={currency.code}
                amount={coupon.discounted_amount}
                ignoreTax={true}
              />
            </span>
          </div>
        ))
      )}
      {isEmpty(coupons) && showForm && (
        <div className="grid-gap20 grid-7525 pt-4">
          <div className="formField mb-0">
            <input
              type="text"
              name="couponcode"
              placeholder="Enter your coupon code"
              value={couponcode || ''}
              onChange={onChangeValue}
            />
          </div>
          <Button
            type="button"
            level="primary"
            size="smallest"
            onClick={onSubmitForm}
          >
            Apply
          </Button>
        </div>
      )}
      <Dialog open={dialogMessage ? true : false} title="Applying coupon" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
    </>
  );
};

export default CouponForm;
