import React, { useRef, useEffect, useState } from 'react';
import Container from '../../atoms/Container/Container'
import CountdownTimer from '../CountdownTimer/CountdownTimer';

import styles from './CountdownBanner.module.css';


const CountdownBanner = props => {
    const { countdownBanners } = props
    const countdownBannersRef = useRef();
    const [banner, setBanner] = useState(false);

    const setBodyTopPadding = () => {
        const bodyTopPadding = window.innerWidth > 800 ? 140 : 110;
        // const initialBannerHeight = window.innerWidth > 800 ? 258 : 279;
        const bannerElHeight = countdownBannersRef.current ? countdownBannersRef.current.clientHeight : 0;
        const newHeight = countdownBannersRef.current ? countdownBannersRef.current.clientHeight : 0;
        
        const finalTopPadding = newHeight ? newHeight : bannerElHeight;
        document.body.style.paddingTop = `${bodyTopPadding + finalTopPadding}px`;
        
    }

    useEffect(() => {
        setBanner(true);

        // TODO: better handling of onload padding
        setTimeout(() => {
            setBodyTopPadding();
        }, 500);
        
    }, [banner]);


    useEffect(() => {
        window.addEventListener('resize', setBodyTopPadding);
        // window.addEventListener('scroll', setBodyTopPadding);
    }, [banner]);
    

    return (countdownBanners && countdownBanners.length > 0 && (
            // eslint-disable-next-line array-callback-return 
            countdownBanners.map((countdownBanner, countdownBannerIndex) => {
                const currentDate = new Date();
                const startDate = new Date(countdownBanner.startDate);
                const endDate = new Date(countdownBanner.endDate);
                const countDownDate = new Date(countdownBanner.data.countdownDate);
                const isWithinDate = startDate <= currentDate && currentDate <= endDate && countDownDate >= currentDate;

                if (isWithinDate && ((countdownBanner.data.url && window.location.pathname === countdownBanner.data.url) || !countdownBanner.data.url)) {
                    
                    return (
                        <div className={styles.countdownBanners} key={countdownBannerIndex} >
                            <section className={styles.root} id={`cd-banner-${countdownBannerIndex}`} ref={countdownBannersRef} >
                                <div className={`${styles.countdownBannersContainer}`} style={{backgroundColor: countdownBanner.data.bannerBackgroundColor}} >
                                    <Container size="large">
                                        <div className={`${styles.countdownBannersBlock}`} >
                                            <div className={`${styles.bannerLogo}`}>
                                                <img
                                                loading="lazy"
                                                src={countdownBanner.data.bannerLogo}
                                                alt="Promotion"
                                                />
                                            </div>
                                            <div className={`${styles.bannerInfo}`}>
                                                <h2 className={`${styles.bannerTitle}`} style={{color: countdownBanner.data.bannerTitleColor}}>
                                                    {countdownBanner.data.bannerTitle}
                                                </h2>
                                                <h5 className={`${styles.bannerSubtitle}`} style={{color: countdownBanner.data.bannerSubtitleColor}}>
                                                    {countdownBanner.data.bannerSubtitle}
                                                </h5>
                                            </div>
                                            <CountdownTimer targetDate={countdownBanner.data.countdownDate} color={countdownBanner.data.countdownColor} bannerId={`cd-banner-${countdownBannerIndex}`}  />
                                        </div>
                                    </Container>
                                </div>
                            </section>
                        </div>
                    )
                }
            })
        )
    
        
    );
};

export default CountdownBanner