import React, { useState } from 'react';
import { subscribeForm } from '../../../helpers/klaviyo';
import Button from '../../atoms/Button/Button';

import styles from './SubscribeForm.module.css';

const SubscribeForm = () => {
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [fields, setFields] = useState({
    email: '',
    first_name: '',
    last_name: ''
  });

  const setFieldValue = (fieldName, fieldValue) => {
    const newField = {};
    newField[fieldName] = fieldValue;
    setFields({ ...fields, ...newField });
  };

  const submitHandler = e => {
    e.preventDefault();
    /* Trade subscribe list ID - XMgVpc */
    /* Retail subscribe list ID - TGBFYb */
    const listId = 'TGBFYb';

    subscribeForm(listId, fields).then(response => {
      if (response && 'status' in response && response.status === 200) {
        setSubmitSuccessful(true);
      }
    });
  };

  return (
    <>
      <div className={`flex-between ${styles.footerListRow}`}>
        <div className={styles.subscribeDesc}>
          <h5 className={styles.footerListHeading}>Sign up for updates</h5>
          <p className="mb-0">
            And receive <strong>10% off</strong> on your first online order.
          </p>
        </div>

        <div className={`flex-end ${styles.subscribeForm}`}>
          <form onSubmit={submitHandler}>
            <div
              className={`${styles.subscribeFields} ${
                submitSuccessful ? 'hide' : 'show'
              }`}
            >
              <div className="formField mb-4 flex-between">
                <input
                  className={`mr-2 ${styles.subscribeInput}`}
                  placeholder="Your first name"
                  type="text"
                  onChange={e => setFieldValue('first_name', e.target.value)}
                />
                <input
                  className={`ml-2 ${styles.subscribeInput}`}
                  placeholder="Your last name"
                  type="text"
                  onChange={e => setFieldValue('last_name', e.target.value)}
                />
              </div>
              <div className="formField mb-0 flex-between">
                <input
                  className={`${styles.subscribeInput}`}
                  placeholder="Enter email address"
                  type="text"
                  id="subscribe"
                  onChange={e => setFieldValue('email', e.target.value)}
                />
                <Button className="ml-4" level="primary" type="buttonSubmit">
                  Sign up
                </Button>
              </div>
            </div>

            <div
              className={`${styles.subscribeSuccess} ${
                submitSuccessful ? 'show' : 'hide'
              }`}
            >
              <h6>Thanks for subscribing</h6>
              <p>Please check your email for a confirmation message.</p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SubscribeForm;
