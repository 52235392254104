import React from "react"
import Icon from '../../atoms/Icon/Icon'
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
import NavWishlist from '../../molecules/NavWishlist/NavWishlist'
import NavCart from '../../molecules/NavCart/NavCart'
// import CurrencySelect from '../../molecules/CurrencySelect/CurrencySelect'

import styles from './UserMenu.module.css'

const UserMenu = ({searchVisibility, searchHandler, isLoggedIn, firstName, pending}) => {
    return (
        <>
            {/* <div className={`${styles.userMenuItem} ${styles.currencySelect}`} role="presentation">
                <CurrencySelect styles={styles} />
            </div> */}
            {/* <div className={`${styles.userMenuItem} ${styles.translate}`} role="presentation">
                <Icon symbol="translate" />
            </div> */}
            <div className={`${styles.userMenuItem} ${styles.search}`} role="presentation" onClick={() => searchHandler(!searchVisibility)}>
                <Icon symbol="search" />
            </div>
            <div className={`${styles.userMenuItem} ${styles.wishlist}`}>
                <NavWishlist styles={styles} />
            </div>
            <div className={`${styles.userMenuItem} ${styles.account} ${isLoggedIn ? styles.loggedIn : null}`}>
                <Icon symbol="user" />
                <div className={styles.userDropdown}>
                    <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} pending={pending} />
                </div>
            </div>
            <div className={`${styles.userMenuItem} ${styles.miniCart}`}>
                <NavCart styles={styles} mode="drawer" />
            </div>
        </>
    )
}

export default UserMenu