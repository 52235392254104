/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect, useState } from "react"
import Helmet from 'react-helmet';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BindContext from '../../../context/BindProvider'
import AuthContext from '../../../context/AuthProvider'

import { setStorage } from "../../../helpers/general";

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

// CSS not modular here to provide global styles
import "./Layout.css"
import "./Globals.css"

const Layout = ({ children }) => {
  const bind = useContext(BindContext);
  const initBind = bind && bind.init;
  const AuthCtx = useContext(AuthContext);
  const userChecked = AuthCtx && AuthCtx.state.userChecked;
  const userId = (AuthCtx && AuthCtx.state && AuthCtx.state.customerId) || null;
  const gaConfig = userChecked ? ((userId !== null) ? `gtag('set', {'custom_map': {'dimension2': 'customerType'}});gtag('event', 'onload', {'customerType': 'retail'});` : ``) : ``;
  const [messages, setMessages] = useState([]);
  const [countdownBanners, setCountdownBanners] = useState([]);
  const [fetchedMessages, setFetchedMessages] = useState(false);
  const [fetchedCountdownBanners, setFetchedCountdownBanners] = useState(false);
  const ignorePages = [
    '/login', 
    '/login/', 
    '/signup', 
    '/signup/', 
    '/forgotpassword', 
    '/forgotpassword/',
    '/stockist-login',
    '/stockist-login/',
  ]
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allBuilderModels {
        globalInfoBannerContent(options: { cachebust: true }) {
          data {
            text
            link
            displayForRetail
            displayForTrade
          }
        }
        countdownBanners (options: { cachebust: true }) {
          everything
        }
      }
    }
  `);

  if (typeof window !== 'undefined' && ignorePages.indexOf(window.location.pathname) === -1) {
    setStorage('lastPage', window.location.href, true);
  }

  useEffect(() => {
    if (messages.length === 0 && !fetchedMessages) {
      setFetchedMessages(true);
      const fetchedMessages = data.allBuilderModels.globalInfoBannerContent.map((message) => {
        return {
          text: message.data.text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'),
          link: message.data.link,
          retail: message.data.displayForRetail,
          trade: message.data.displayForTrade,
        }
      });
      setMessages(fetchedMessages);
    } else if (countdownBanners.length === 0 && !fetchedCountdownBanners) {
      setFetchedCountdownBanners(true);
      const fetchedCountdownBanners = data.allBuilderModels.countdownBanners.map((countdownBanner) => {
        return {
          data: countdownBanner.everything.data,
          startDate: countdownBanner.everything.startDate,
          endDate: countdownBanner.everything.endDate,
        }
      });
      setCountdownBanners(fetchedCountdownBanners);
    }
    initBind()
  }, [initBind, data, messages, setMessages, fetchedMessages, setFetchedMessages, countdownBanners, setCountdownBanners, fetchedCountdownBanners, setFetchedCountdownBanners])

  return (
    <>
      <Helmet script={[{
          type: 'text/javascript',
          innerHTML: gaConfig
        }]}
      />
      {/* Google Tag Manager - Body Script */}
      <noscript>
          {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KRK88NJ"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
      </noscript>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} messages={messages} countdownBanners={countdownBanners} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
