import React, { useMemo } from 'react';
import Icon from '../../atoms/Icon/Icon';
import styles from './NavSocialMedia.module.css';
// TODO: Look to replace this default with data from an external system, in the same structure.
import defaultSocialItems from './NavSocialMedia-example.json';

const NavSocialMedia = ({ socialItems, label }) => {
  const socialsToRender = useMemo(() => {
    return socialItems || defaultSocialItems;
  }, [socialItems]);

  return (
    <>
      {label && <span className={styles.socialsLabel}>{label}</span>}
      {socialsToRender.map((item, itemIndex) => (
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          key={itemIndex}
          className={styles.inline}
        >
          <Icon symbol={item.icon} />
        </a>
      ))}
    </>
  );
};

export default NavSocialMedia;
