import React from 'react';
import clsx from 'clsx';
import Loader from '../../atoms/Loader/Loader';
import styles from './AdjustItem.module.css';

const AdjustItem = ({ item, updatingItem, updateCartItemQuantity, isDonation }) => {
  const isUpdating = updatingItem === item.id;
  return (
    <div className={clsx(styles.root, isUpdating && 'disable-action')}>
      {/* <div
        data-button
        role="presentation"
        className={styles.AdjustButton}
        onClick={() => updateCartItemQuantity(item, 'minus')}
      >
        -
      </div> */}

      {/* <div className={styles.AdjustButton}>
        {isUpdating ? <Loader /> : item.quantity}
      </div> */}
      {isUpdating ? 
        <Loader /> : 
        
        <input type="text" defaultValue={item.quantity} disabled={isDonation ? 'disabled' : ''} onBlur={(e) => updateCartItemQuantity(item, e.target.value)} />
      }

      {/* <div
        data-button
        role="presentation"
        className={styles.AdjustButton}
        onClick={() => updateCartItemQuantity(item, 'plus')}
      >
        +
      </div> */}
    </div>
  );
};

export default AdjustItem;
