import React, { useRef, useState, useEffect, useCallback } from 'react';
import Link from 'gatsby-link';
import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia'
import Container from '../../atoms/Container/Container'
import MapMarker from '../../atoms/Icon/map-marker'

import styles from './InformationBar.module.css';

const InformationBar = props => {
    const { messages } = props
    const messagesRef = useRef()
    const [initRotate, setInitRotate] = useState(false)

    const rotate = useCallback((index) => {
        setTimeout(() => {
            const container = messagesRef.current;
            if (container) {
                const messageContainers = Array.from(container.children);
                const currentChild = messageContainers[index];
                let nextIndex = index + 1;
                if (nextIndex >= messageContainers.length) {
                    nextIndex = 0;
                }
                const nextChild = messageContainers[nextIndex];
                messageContainers.map((msg) => {
                    msg.classList.remove(styles.rollout);
                    return true;
                });
                currentChild.classList.remove(styles.active)
                currentChild.classList.add(styles.rollout);
                nextChild.classList.add(styles.active);
                setTimeout(() => { currentChild.classList.remove(styles.rollout); }, 600);
                rotate(nextIndex);
            }
        }, 4000)
    }, [messagesRef]);

    useEffect(() => {
        if (messages.length > 1 && !initRotate) {
            setInitRotate(true);
            rotate(0);
        }
    }, [messages, initRotate, setInitRotate, rotate])

    return (
        <section className={styles.root}>
            <Container size="large">
                <div className={styles.socials}>
                    <NavSocialMedia />
                </div>
                <div className={styles.messages} ref={messagesRef}>
                    {messages && messages.length > 0 && (
                        messages.filter(message => message.retail).map((message, messageIndex) => {
                            const activeClass = messageIndex === 0 ? styles.active : '';
                            
                            return (
                                <div className={`${styles.message} ${activeClass}`} key={messageIndex}>
                                    {message.link && (
                                        <a href={message.link}>&nbsp;</a>
                                    )}
                                    <span dangerouslySetInnerHTML={{ __html: message.text }}></span>
                                </div>
                            )
                        })
                    )}
                </div>
                <div className={styles.storeFinder}>
                    <Link to="/find-your-store">
                        <MapMarker /> <span className={styles.storeFinderContent}>Find your Store</span><span className={styles.storeFinderArrow} />
                    </Link>
                </div>
            </Container>
        </section>
    );
};

export default InformationBar