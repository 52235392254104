import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import CartContext from '../../../context/CartProvider';
import { get } from 'lodash';
import CartSummary from '../../molecules/CartSummary/CartSummary';
import CartHeader from './components/CartHeader';
import CustomItems from './components/CustomItems';
import StandardItems from './components/StandardItems';
import GiftCertificateItems from './components/GiftCertificateItems';

import styles from './Cart.module.css';

import { dataLayerPush } from '../../../helpers/general';

// TODO: Update buttons to atom component
// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const Cart = ({ cartType, onClick }) => {
  const [firstRender, setFirstRender] = useState(true);
  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const { state, removeItemFromCart, updateCartItemQuantity } = ctx;
  const { currency, cartAmount, lineItems, productInfo, numberItems } = state.cart;
  const { updatingItem, cartLoading, cart, checkout } = state;
  const donationProduct = get(ctx, 'state.donationProduct', false);

  useEffect(() => {
    if (firstRender) {
      const productsObject = lineItems.physical_items;
      dataLayerPush('view_cart', {
        currency: 'AUD',
        value: cartAmount
      }, productsObject);

      if (typeof window !== undefined && window.juapp && productsObject && productsObject.length) {
        const currentJustUnoProducts = productsObject.map(product => {
          return {
            productid: `${product.product_id}_${product.variant_id}`,
            variantid: "",
            sku: product.sku,
            name: product.name,
            price: product.sale_price || product.price || 0,
            quantity: product.quantity
          }
        });

        window.juapp(
          'cartItems',
          currentJustUnoProducts
        );

        if (currentJustUnoProducts && currentJustUnoProducts.length) {
          window.juapp('cart', {
              total: cart.cartAmount,
              subtotal: cart.baseAmount,
              tax: cart.taxAmount,
              shipping: checkout ? checkout.shipping_cost_total_inc_tax : 0,
              currency: 'AUD'
            }
          );
        }

      }
      setFirstRender(true);
    }
  }, [firstRender, cartAmount, lineItems.physical_items, cart.cartAmount, cart.taxAmount, checkout]);

  if (cartLoading) {
    return (
      <div className="bc-cart__empty">
        <h2 className="bc-cart__title--empty">
          <em>Loading Cart</em>
        </h2>
      </div>
    );
  }

  if (!numberItems) {
    return (
      <div className="text-center">
        <p className="subtitle small text-center">Your cart is empty.</p>
        <Link to="/shop/" className={styles.bcCartLink}>
          Take a look around.
        </Link>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        cartType === 'full' && styles.bcCartContainer,
        cartType === 'mini' && styles.bcMiniCartContainer
      )}
    >
      <div className="bc-cart-error">
        <p className="bc-cart-error__message"></p>
      </div>

      {cartType === 'full' && <CartHeader />}
      <div className={clsx(cartType === 'mini' && styles.bcMiniCartContent)}>
        <div className="bc-cart-list" data-cartitemlist>
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.physical_items}
            productInfo={productInfo}
            cartType={cartType}
            donationProductId={donationProduct ? donationProduct.id : false}
          />
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.digital_items}
            productInfo={productInfo}
            cartType={cartType}
          />
          <CustomItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.custom_items}
            productInfo={productInfo}
            cartType={cartType}
          />
          <GiftCertificateItems
            currency={currency}
            updatingItem={updatingItem}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.gift_certificates}
            productInfo={productInfo}
            cartType={cartType}
          />
        </div>
        {cartType === 'mini' && <CartSummary cartType={cartType} onClick={onClick} />}
      </div>
    </div>
  );
};

export default Cart;
