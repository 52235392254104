import React, { useEffect, useMemo, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Container from '../../atoms/Container/Container';

import styles from './SiteMenu.module.css';

const SiteMenu = ({ setNavOpen }) => {
  const [activeNav, setActiveNav] = useState(0);
  const [retailMenu, setRetailMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState([]);

  const menuData = useStaticQuery(graphql`
    query {
      allBuilderModels {
        menus(options: { cachebust: true }) {
          name
          data {
            menuItems
          }
        }
      }
    }
  `);

  const mobileClick = (e, subNavId, back) => {
    if (back) {
      e.stopPropagation();
      const currentSubNav = e.currentTarget.closest('[data-subnav]');
      currentSubNav.classList.toggle(styles.isActive);
    } else {
      const subNav = e.currentTarget.querySelectorAll(
        `[data-subnav="${subNavId}"]`
      );
      if (subNav.length > 0) {
        e.stopPropagation();
        subNav[0].classList.toggle(styles.isActive);
      }
    }
  };

  const openSubnav = (e, subNavId) => {
    const subNav = e.currentTarget.parentNode.querySelectorAll(
      `[data-subnav="${subNavId}"]`
    );
    if (subNav.length > 0) {
      e.stopPropagation();
      e.currentTarget.classList.toggle(styles.active);
      subNav[0].classList.toggle(styles.isActive);
    }
  };

  const linkTo = (e, link) => {
    e.preventDefault();
    /* Force page to reload to link in the event a link with only filter changes is clicked */
    if (typeof window !== "undefined") {
      window.location = link;
    }
  }

  const toggleActiveNav = (e, itemIndex, level, childrenItems) => {
    if (typeof window !== 'undefined' && window.innerWidth >= 800 && window.innerHeight > 630) {
      if (level === 1 && childrenItems) {
        setNavOpen(true);
      }
      if (level === 2) {
        setActiveNav(itemIndex);
      }
    }
  };

  const closeActiveNav = (level, childrenItems) => {
    if (typeof window !== 'undefined' && window.innerWidth >= 800 && window.innerHeight > 630) {
      if (level === 1 && childrenItems) {
        setNavOpen(false);
      }
    }
  };

  const isActiveNav = (itemIndex, level) => {
    if (level === 2) {
      if (activeNav === itemIndex) return true;
    }

    return false;
  };

  const cleanseLink = (link) => {
    if (link.startsWith('https://spencil.com.au')) {
      return link.replace('https://spencil.com.au', '');
    }

    return link;
  }

  useEffect(() => {
    setActiveMenu(retailMenu);
  }, [retailMenu])

  const menuLevel = (menuList, parentLabel, className, level, parentIndex) => {
    const items = menuList?.map((item, itemIndex) => {
      if (item.menuLabel === 'ImageGridStyle' && level === 3) {
        if (item.submenu && item.submenu.length > 0) {
          return (
            <li key={itemIndex} className={styles.imageGrid}>
              {item.submenu?.map((image, imageIndex) => {
                return (
                  <div key={imageIndex} className={styles.imageBlock}>
                    <a href={image.menuLink} onClick={(e) => linkTo(e, image.menuLink)}>
                      <img
                        loading="lazy"
                        src={image.image}
                        alt={image.menuLabel}
                        width="250"
                        height="130"
                      />
                      <span>{image.menuLabel}</span>
                    </a>
                  </div>
                );
              })}
            </li>
          );
        } else {
          return null;
        }
      } else {
        let childrenItems = null;
        if (item.submenu && item.submenu.length > 0) {
          childrenItems = menuLevel(
            item.submenu,
            item.menuLabel,
            styles.subnav,
            level + 1,
            itemIndex
          );
        }

        return (
          <li
            key={itemIndex}
            className={`${isActiveNav(itemIndex, level) ? styles.active : ''} ${
              childrenItems ? styles.withChildren : ''
            }`}
            onMouseEnter={e =>
              toggleActiveNav(e, itemIndex, level, childrenItems)
            }
            onMouseLeave={() => closeActiveNav(level, childrenItems)}
            onClick={e => mobileClick(e, `${itemIndex}-${level + 1}`)}
            role="presentation"
          >
            {childrenItems && (
              <span className={styles.hasChildren} onClick={e => openSubnav(e, `${itemIndex}-${level + 1}`)} role="presentation"></span>
            )}
            {item.menuLink && item.menuLink !== '' && !item.target && (
              <Link to={cleanseLink(item.menuLink)} onClick={(e) => linkTo(e, cleanseLink(item.menuLink))}>{item.menuLabel}</Link>
            )}
            {item.menuLink && item.menuLink !== '' && item.target && (
              <a href={item.menuLink} title={item.menuLabel} onClick={(e) => linkTo(e, item.menuLink)}>
                {item.menuLabel}
              </a>
            )}
            {(!item.menuLink || item.menuLink === '') && (
              <span className={`${styles.fauxLink} ${item.menuLabel === parentLabel ? styles.duplicate : ''}`}>{item.menuLabel}</span>
            )}
            {childrenItems && childrenItems}
          </li>
        );
      }
    });

    return (
      <>
        {level === 2 && (
          <div
            className={`${className} ${styles[`level${level}`]}`}
            data-subnav={`${parentIndex}-${level}`}
          >
            <span
              role="presentation"
              className={styles.backLink}
              onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}
            >
              Back
            </span>
            {parentLabel && (
              <span className={styles.parentLabel}>{parentLabel}</span>
            )}
            {/* <div className={`${styles.shadow} ${items.length <= 1 ? styles.conceal : ''}`}> */}
            <div className={styles.shadow}>
              <Container size="large">
                <ul>{items}</ul>
              </Container>
            </div>
          </div>
        )}
        {level !== 2 && (
          <div
            className={`${className} ${styles[`level${level}`]}`}
            data-subnav={`${parentIndex}-${level}`}
          >
            <span
              role="presentation"
              className={styles.backLink}
              onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}
            >
              Back
            </span>
            {parentLabel && (
              <span className={styles.parentLabel}>{parentLabel}</span>
            )}
            <ul>{items}</ul>
          </div>
        )}
      </>
    );
  }

  useMemo(() => {
    if (activeMenu.length === 0) {
      menuData?.allBuilderModels.menus.map(menu => {
        if (menu.name === 'Header') {
          const retailMenuItems = menu.data.menuItems;
          setRetailMenu(retailMenuItems);
        }
        return true;
      });
    }
  }, [activeMenu, menuData]);

  return menuLevel(activeMenu, null, styles.root, 1, 0);
};

export default SiteMenu;
