import React, { useContext, useMemo, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Icon from '../../atoms/Icon/Icon';
import Cart from '../../organisms/Cart/Cart';
import CartContext from '../../../context/CartProvider';
import navCartStyles from './NavCart.module.css';

// INHERIT STYLES FROM PARENT COMPONENT

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

/**
 * @param {string} mode - cart view mode, 'bubble' | 'drawer'
 * @param {object} styles - an object of class names
 * @returns {JSXElement} Nav Cart button
 */

const NavCart = ({ styles, mode }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const megredStyles = { ...(styles || {}), ...navCartStyles };
  const cart = useContext(CartContext);

  const numberBadge = useMemo(() => {
    const numberItems = get(cart, 'state.cart.numberItems');

    if (!numberItems) {
      return null;
    }
    return <span className={megredStyles.number}>{numberItems}</span>;
  }, [cart, megredStyles.number]);

  const isBubble = mode === 'bubble';

  const toggleDrawer = isOpen => () => {
    if (!isBubble) {
      setIsOpenDrawer(isOpen);
    }
  };
  return (
    <>
      <span role="presentation" onClick={toggleDrawer(true)}>
        <Icon symbol="bag" />
      </span>
      {numberBadge}
      {isBubble && (
        <div className={megredStyles.userDropdown}>
          <Cart cartType="mini" />
        </div>
      )}
      {!isBubble && (
        <Drawer
          anchor="right"
          open={isOpenDrawer}
          PaperProps={{
            className: megredStyles.miniCartDrawer
          }}
          onClose={toggleDrawer(false)}
        >
          <div className={megredStyles.miniCartTitle}>
            <span className="subtitle" data-carttitle>
              My Bag
            </span>
            <span
              role="presentation"
              data-closebtn
              onClick={toggleDrawer(false)}
            >
              Close
            </span>
          </div>
          <Cart cartType="mini" onClick={toggleDrawer(false)}/>
        </Drawer>
      )}
    </>
  );
};

NavCart.propTypes = {
  styles: PropTypes.shape({}),
  mode: PropTypes.oneOf(['bubble', 'drawer'])
};

NavCart.defaultProps = {
  styles: {},
  mode: 'bubble'
};

export default NavCart;
