import React from 'react';
// import { Link } from 'gatsby';
import Icon from '../../atoms/Icon/Icon'
import Button from '../../atoms/Button/Button'
import ProductTab from '../../molecules/ProductTab/ProductTab'
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';

import WishlistContext from '../../../context/WishlistProvider';
import CartContext from '../../../context/CartProvider';

// INHERIT STYLES FROM PARENT COMPONENT

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current &&  ref.current.focus()}

    return {setFocus, ref} 
}

const NavWishlist = class extends React.Component {
    constructor(props) {
        super(props);
        this.styles = props.styles || {};
        this.state = {
            wishlistName: '',
            updateInProgress: false,
            toggleSave: false
        }
        this.inputFocus = utilizeFocus()
    }

    saveWishlist() {
        this.setState({toggleSave: !this.state.toggleSave});
        this.inputFocus.setFocus()
    }

    handleSubmit(e, wishlistContext) {
        // if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        wishlistContext.saveWishlist(this.state.wishlistName);
        // }
    }

    render() {
        return (
            <CartContext.Consumer>
                {cartContext => {
                    return (
                        <WishlistContext.Consumer>
                            {wishlistContext => {
                            return (
                                <>
                                    <Icon symbol="heart" />
                                    {wishlistContext &&
                                        wishlistContext.sessionWishlistData &&
                                        wishlistContext.sessionWishlistData.length > 0 && (
                                        <span className={'number' in this.styles ? this.styles.number : ''}>{wishlistContext.sessionWishlistData.length}</span>
                                    )}

                                    <div className={`${this.styles.userDropdown} ${this.styles.wishlistDropdown}`}>
                                        <div className={this.styles.wishlistProducts}>
                                            {wishlistContext &&
                                                wishlistContext.sessionWishlistData &&
                                                wishlistContext.sessionWishlistData.length > 0 && 
                                                wishlistContext.sessionWishlistData.map((product, productIndex) => (
                                                    <ProductTab 
                                                        key={productIndex} 
                                                        data={product.node} 
                                                        addToCart={true} 
                                                        additionalAddAction={() => wishlistContext.updateSessionWishlist(product.node.entityId, ( product.node.variants.edges.length > 0 ? product.node.variants.edges[0].node.entityId : null ))} 
                                                        removeFunction={() => wishlistContext.updateSessionWishlist(product.node.entityId, ( product.node.variants.edges.length > 0 ? product.node.variants.edges[0].node.entityId : null ))} 
                                                    />
                                            ))}
                                            {(!wishlistContext || (wishlistContext &&
                                                wishlistContext.sessionWishlistData &&
                                                wishlistContext.sessionWishlistData.length === 0)) && (
                                                    <span className={this.styles.helperDescription}>
                                                        Click the <Icon symbol="heart" /> icon on products to add to your wishlist
                                                    </span>
                                            )}
                                        </div>

                                        {wishlistContext &&
                                            wishlistContext.sessionWishlistData &&
                                            wishlistContext.sessionWishlistData.length > 0 && (
                                                <>
                                                    <div className={'bulkActions' in this.styles ? this.styles.bulkActions : ''}>
                                                        <ConfirmDialog
                                                            message="Would you also like to clear the wishlist?"
                                                            btnOk="Yes"
                                                            onOk={() => {
                                                                cartContext.addAllToCart(wishlistContext.sessionWishlistIds); 
                                                                wishlistContext.clearSessionWishlist();
                                                            }}
                                                            btnCancel="No"
                                                            onCancel={() => cartContext.addAllToCart(wishlistContext.sessionWishlistIds)}
                                                        >
                                                            <Button level="primary" size="small" type="span">Add all to cart</Button>
                                                        </ConfirmDialog>
                                                        <Button level="secondary" size="small" type="span" onClick={() => this.saveWishlist()}>Save wishlist</Button>
                                                    </div>
                                                    <form className={`${this.styles.saveWishlist} ${this.state.toggleSave ? this.styles.active : null}`} onSubmit={(e) => this.handleSubmit(e, wishlistContext)}>
                                                        <div className={`formField ${this.styles.formField}`}>
                                                            <input type="text" ref={this.inputFocus.ref} placeholder="Name your wishlist..." onChange={(e) => this.setState({wishlistName: e.target.value})} />
                                                        </div>
                                                        <div className={`formField ${this.styles.formField} ${'bulkActions' in this.styles ? this.styles.bulkActions : ''}`}>
                                                            <Button level="primary" size="small" type="buttonSubmit" disabled={this.state.updateInProgress}>
                                                                {this.state.updateInProgress && (
                                                                    <span>Saving...</span>
                                                                )}
                                                                {!this.state.updateInProgress && (
                                                                    <span>Save wishlist</span>
                                                                )}
                                                            </Button>
                                                            <Button level="secondary" size="small" type="span" disabled={this.state.updateInProgress} onClick={() => this.saveWishlist()}>Cancel</Button>
                                                        </div>
                                                    </form>
                                                </>
                                        )}
                                    </div>
                                </>
                            );
                            }}
                        </WishlistContext.Consumer>
                    );
                }}
            </CartContext.Consumer>
        )
    }
}

export default NavWishlist;