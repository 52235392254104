import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import Icon from '../../atoms/Icon/Icon';
import ProcessSearch from './ProcessSearch.js';

import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import Container from '../../atoms/Container/Container';

import styles from './Search.module.css';

const Search = ({ toggleSuggestions, showing }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setFocused] = useState(false);
  const [overrideRedirect, setOverrideRedirect] = useState(null);
  const input = useRef(null);

  const performSearch = () => {
    const results = suggestionsCount();
    if (
      typeof overrideRedirect === 'string' &&
      overrideRedirect.trim() !== ''
    ) {
      window.location.href = overrideRedirect;
    } else if (results.count === 1) {
      window.location = results.items[0].custom_url.url;
    } else {
      window.location = `/search/?q=${encodeURIComponent(searchTerm)}`;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    performSearch();
  };

  const suggestiveSearch = _.debounce((term) => {
    if (term.length >= 3) {
      ProcessSearch(term, 1000, {}).then((results) => {
        const searchResults = results.items.map((dataSet) => {
          const modifier = { ...dataSet };
          if ('overrideRedirect' in modifier) {
            setOverrideRedirect(modifier.overrideRedirect);
          }

          if ('products' in modifier) {
            modifier.products = modifier.products.filter(
              (product) =>
                product.product_categories.length > 0 &&
                product.product_categories[0].path.startsWith('/shop/')
            );

            if (modifier.allItems) {
              modifier.allItems = modifier.allItems.filter(
                (product) =>
                  product.product_categories.length > 0 &&
                  product.product_categories[0].path.startsWith('/shop/')
              );
            }
          }

          return modifier;
        });
        // console.log('Search results:', searchResults);
        setSuggestions(searchResults);
        if (toggleSuggestions) {
          toggleSuggestions(true);
        }
      });
    } else {
      if (toggleSuggestions) {
        toggleSuggestions(false);
      }
      setSuggestions([]);
    }
  }, 200);

  const actionSuggestiveSearch = (term) => {
    // console.log('Action search :', term);
    setFocused(true);
    setSearchTerm(term);
    suggestiveSearch(term);
  };

  const suggestionsCount = () => {
    let merger = [];
    suggestions.forEach((suggestion) => {
      const items = Object.values(suggestion);
      merger = merger.concat(items[0]);
    });

    return { count: merger.length, items: merger };
  };

  const focusInput = _.debounce(() => {
    input.current?.focus();
  }, 310);

  useEffect(() => {
    if (input.current && !isFocused && showing) {
      focusInput();
    }
    if (!showing) {
      setFocused(false);
    }
  }, [showing, isFocused]);

  useEffect(() => {
    if (window && document && document.body) {
      if (showing && !_.isEmpty(suggestions) && window.innerWidth < 767) {
        document.body.classList.add('disable-scrolling');
      } else {
        document.body.classList.remove('disable-scrolling');
      }
    }
  }, [showing, suggestions]);

  return (
    <>
      <form
        className={`${styles.root} ${showing ? 'showing' : ''}`}
        onSubmit={submitHandler}
      >
        <input
          className={styles.input}
          ref={input}
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => actionSuggestiveSearch(e.target.value)}
          onFocus={(e) => actionSuggestiveSearch(e.target.value)}
          onBlur={() => setFocused(false)}
        ></input>
        <button className={styles.button} type="submit">
          <Icon symbol="search" />
        </button>
      </form>
      {toggleSuggestions && suggestions.length > 0 && (
        <div className={styles.suggestions}>
          <Container size="large">
            {suggestions.map((suggestion, suggestionIndex) => {
              if ('products' in suggestion && 'allItems' in suggestion) {
                const displayProducts = suggestion.allItems.slice(0, 4);
                return (
                  <React.Fragment key={suggestionIndex}>
                    <div className={styles.viewAll}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          performSearch();
                        }}
                      >
                        See all {suggestion.allItems.length} results{' '}
                        <Icon symbol="arrowRight" />
                      </a>
                    </div>
                    <ProductGrid products={displayProducts} />
                  </React.Fragment>
                );
              }

              if ('posts' in suggestion) {
                return (
                  <div key={suggestionIndex}>
                    {suggestion.posts &&
                      suggestion.posts.map((post, postIndex) => (
                        <a key={postIndex} href={post.url}>
                          {post.title}
                        </a>
                      ))}
                  </div>
                );
              }

              return null;
            })}
          </Container>
        </div>
      )}
    </>
  );
};

export default Search;
