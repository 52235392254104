import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import AuthContext from '../../../context/AuthProvider';
import Container from "../../atoms/Container/Container";
import InformationBar from "../../molecules/InformationBar/InformationBar";
import CountdownBanner from "../../molecules/CountdownBanner/CountdownBanner";
import Logo from "../../atoms/Logo/Logo";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import SiteMenu from '../../molecules/SiteMenu/SiteMenu'
import UserMenu from '../../molecules/UserMenu/UserMenu'
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
import Search from '../../molecules/Search/Search'

import styles from './Header.module.css';

const Header = ({ siteTitle, messages, countdownBanners }) => {
  const [showSearch, setShowSearch] = useState(false)
  const [searchOverflow, setSearchOverflow] = useState(false)
  const [isNavOpen, setNavOpen] = useState(false);
  const [isActiveAccountMenu, toggleAccountMenu] = useState(false);

  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const firstName = auth && auth.state.object && auth.state.object.first_name;
  const pending = false;

  const hideSearch = () => {
    setSearchOverflow(false)
    setShowSearch(!showSearch);
  }

  const allowSearchOverflow = (toggle) => {
    setSearchOverflow(toggle)
  }

  return (
    <section className={styles.headerWrap} data-header={true}>
      <div className={`${styles.shroud} ${isNavOpen ? styles.isActive : ''}`} onClick={() => setNavOpen(!isNavOpen)} role="presentation"></div>
      <InformationBar messages={messages} />
      <CountdownBanner countdownBanners={countdownBanners} />
      <header className={styles.header}>
        <Container size="large">
          {/* Mobile menu */}
          <div className={styles.burgerMenu}>
            <div className={`${styles.menuIcon}`} role="presentation" onClick={() => setNavOpen(!isNavOpen)}> 
              <Icon symbol="hamburger" />
            </div>
            <div className={`${styles.searchIcon}`} role="presentation" onClick={() => setShowSearch(!showSearch)}>
                <Icon symbol="search" />
            </div>
          </div>

          {/* Search */}
          <div className={`${styles.searchWrapper} ${showSearch ? styles.active : ''} ${searchOverflow ? styles.overflow : ''}`}>
            <div className={styles.search}>
              <span className={styles.close} role="presentation" onClick={() => hideSearch()}><Icon symbol="close" /></span>
              <Search toggleSuggestions={allowSearchOverflow} showing={showSearch} />
            </div>
          </div>

          {/* Logo */}
          <div className={styles.title}>
            <Link to={"/"}><Logo siteTitle={siteTitle} /></Link>
          </div>

          {/* Site menu */}
          <div className={`${styles.siteMenu} ${isNavOpen ? styles.isActive : ''}`}>
            <div className={`${styles.siteMenuBody} `}>
              <div className={styles.mobileAccount}>
                {!isLoggedIn && (
                  <>
                    <div className={`flex-between ${styles.buttonGroup}`}>
                      <Button level="primary" size="small" href="/login">Login</Button>
                      <Button level="secondary" size="small" href="/signup">Signup</Button>
                    </div>
                    <p>Save your favourite items, speed up the checkout and track your orders.</p>
                  </>
                )}
                {isLoggedIn && (
                  <div className={styles.accountMenu}>
                    <span role="presentation" className={styles.fauxLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}><Icon symbol="user" /> Hi {firstName}</span>
                    <div className={`${styles.subnav} ${isActiveAccountMenu ? styles.isActive : ''}`} data-accountmenu>
                      <span role="presentation" className={styles.backLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}>Back</span>
                      <span className={styles.parentLabel}>My Account</span>
                      <div className={styles.accountItems}>
                        <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} pending={pending} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <SiteMenu setNavOpen={setNavOpen} />
            </div>

            <div className={styles.closeNav} onClick={() => setNavOpen(!isNavOpen)} onKeyUp={() => {}} role="button" tabIndex={0} aria-label="Button"></div>
          </div>

          {/* User menu */}
          <div className={styles.userMenu}>
            <UserMenu searchVisibility={showSearch} searchHandler={setShowSearch} isLoggedIn={isLoggedIn} firstName={firstName} pending={pending} />
          </div>

        </Container>
      </header>
      
    </section>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
