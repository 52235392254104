import React from "react"
import styles from './FooterList.module.css'

const FooterList = ({nav, isOpen, handleClick}) => {
    const subNav = nav.submenu;
    
    return ( 
        <ul className={`${styles.footerListNav} ${isOpen && isOpen ? styles.isOpen : ""}`}>
            <li className={`small ${styles.footerListHeading} ${styles.footerListHeadingMobile}`}  onClick={ () => handleClick(nav.menuLabel) } role='presentation'>{nav.menuLabel} </li>
            
            {subNav.map(item => (
                <li key={item.menuLabel}>
                    <a className={`${styles.footerListItem} ${styles.footerListItemMobile}`} href={item.menuLink}>{item.menuLabel}</a>
                </li>
            ))}
        </ul>
    );
}
export default FooterList;
