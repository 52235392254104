import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';
import CurrencyFormatter from '../../../atoms/CurrencyFormatter/CurrencyFormatter';
import Icon from '../../../atoms/Icon/Icon';
import AdjustItem from '../../../molecules/AdjustItem/AdjustItem';

import styles from '../Cart.module.css';

const CartItem = ({
  cartType,
  currency,
  item,
  productInfo,
  itemType,
  updatingItem,
  removeItemFromCart,
  updateCartItemQuantity,
  donationProductId
}) => {
  const [showAdjustMobile, setShowAdjustMobile] = useState(false);

  // const catalogPrices = useStaticQuery(graphql`
  //   query detailedCatalogPrices {
  //     productPrices: allBigCommerceProductPricing {
  //       nodes {
  //         product_id
  //         price {
  //           as_entered
  //           entered_inclusive
  //           tax_exclusive
  //           tax_inclusive
  //         }
  //         retail_price {
  //           as_entered
  //           entered_inclusive
  //           tax_exclusive
  //           tax_inclusive
  //         }
  //         sale_price {
  //           is_on_sale
  //           as_entered
  //           entered_inclusive
  //           tax_exclusive
  //           tax_inclusive
  //         }
  //         calculated_price {
  //           as_entered
  //           entered_inclusive
  //           tax_exclusive
  //           tax_inclusive
  //         }
  //       }
  //     }
  //   }
  // `);

  const [title, uri, sku] = useMemo(() => {
    const url = new URL(item.url);
    const productUri = url.pathname;
    if (itemType === 'gift') {
      return [
        `${item.name} - Gift Certificate for ${item.recipient.name}`,
        productUri,
        `Theme: ${item.theme}`
      ];
    }
    return [item.name, productUri, item.sku];
  }, [itemType, item]);

  const itemImage = useMemo(() => {
    if (cartType === 'full') {
      return (
        <div className={styles.bcCartImage}>
          <Link to={uri}>
            {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {itemType === 'custom' && (
              <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
            )}
          </Link>
        </div>
      );
    }
    if (cartType === 'mini') {
      return (
        <div className={styles.bcMiniCartImage}>
          <Link to={uri}>
            {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {itemType === 'custom' && (
              <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
            )}
          </Link>
        </div>
      );
    }
  }, [cartType, item, itemType, uri]);

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()} ${dateObj.toLocaleString('default', { month: 'long' })}, ${dateObj.getFullYear()}`;
  }

  const displayAmount = (item, single) => {
    // const detailedFigures = catalogPrices && catalogPrices.productPrices.nodes.filter(itemPrices => itemPrices.product_id === item.product_id);
    // console.log(item, detailedFigures);
    // const multiplier = !single ? item.quantity : 1;
    const values = !single ? { list: item.extended_list_price, sale: item.extended_sale_price } : { list: item.list_price, sale: item.sale_price };

    return values.sale || item.amount;
  }

  const isMiniCart = cartType === 'mini';
  const isUpdating = updatingItem === item.id;

  return (
    <div
      className={clsx(
        styles.bcCartItem,
        isMiniCart && styles.bcMiniCartItem,
        isUpdating && styles.disableAction
      )}
      data-sku={sku}
    >
      <div
        className={clsx(
          !isMiniCart && 'row',
          isMiniCart && styles.bcMiniCartItemRow
        )}
      >
        {itemImage}
        <div
          className={clsx(
            !isMiniCart && styles.bcCartItemMeta,
            isMiniCart && styles.bcMiniCartItemMeta
          )}
        >
          <div className={styles.bcProductTitle}>
            <Link to={uri}>{title}</Link>
          </div>
          <div className={styles.bcProductDetail}>
            Qty: {item.quantity}
            {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
              <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
            )}
          </div>
          {/* mobile view */}
          <div className={styles.bcCartMobileDetails}>
            {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
              <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
            )}
            <div className="flex-start">
              <span>Quantity</span>
              <span className={clsx(styles.bcCartPrice, 'mx-3')}>
                {item.quantity}
              </span>
              <span
                data-button
                role="presentation"
                className={clsx(
                  styles.bcCartOpenAdjust,
                  showAdjustMobile && styles.bcCartCloseAdjust,
                  'icon-wrap'
                )}
                onClick={() => setShowAdjustMobile(!showAdjustMobile)}
              >
                <Icon symbol="caretDown" />
              </span>
            </div>
            {showAdjustMobile && (
              <div className={styles.bcCartMobileAdjust}>
                <AdjustItem
                  item={item}
                  updatingItem={updatingItem}
                  updateCartItemQuantity={updateCartItemQuantity}
                />
              </div>
            )}
          </div>
        </div>
        {isMiniCart && (
          <div className={styles.bcMiniCartPrice}>
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
            <div className={styles.bcCartPrice}>
              <CurrencyFormatter
                currency={currency.code}
                amount={displayAmount(item, false)}
                ignoreTax={true}
              />
            </div>
          </div>
        )}
      </div>

      {!isMiniCart && (
        <div className={styles.bcCartDetils}>
          <div className={clsx('flex-middle', styles.bcCartPrice)}>
            <CurrencyFormatter
              currency={currency.code}
              amount={displayAmount(item, true)}
              ignoreTax={true}
            />
          </div>
          <div className={clsx('flex-center', styles.bcCartItemQty)}>
            <AdjustItem
              item={item}
              cartType={cartType}
              updatingItem={updatingItem}
              updateCartItemQuantity={updateCartItemQuantity}
              isDonation={item.product_id === donationProductId ? true : false}
            />
          </div>
          <div className={clsx('flex-between', styles.bcCartPrice)}>
            <CurrencyFormatter
              currency={currency.code}
              amount={displayAmount(item, false)}
              ignoreTax={true}
            />
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
          </div>
        </div>
      )}

      {!isMiniCart && (
        <div className={styles.bcCartMobileItem}>
          <span
            data-button
            role="presentation"
            className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
            onClick={() => removeItemFromCart(item.id)}
          >
            <Icon symbol="close" />
          </span>
          <span className={styles.bcCartPrice}>
            <CurrencyFormatter
              currency={currency.code}
              amount={displayAmount(item, false)}
              ignoreTax={true}
            />
          </span>
        </div>
      )}
    </div>
  );
};

CartItem.propTypes = {
  removeItemFromCart: PropTypes.func,
  updateCartItemQuantity: PropTypes.func
};

CartItem.defaultProps = {
  removeItemFromCart: () => null,
  updateCartItemQuantity: () => null
};

export default CartItem;
