import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import styles from './PaymentMethods.module.css';

const PaymentMethods = ({ socialItems, label }) => {
  return (
    <>
      <div className={styles.PaymentMethodsContainer}>
        <Icon symbol="visa" />
        <Icon symbol="mastercard" />
        <Icon symbol="paypal" />
        <Icon symbol="afterpay" />
        <Icon symbol="apple" />
        <Icon symbol="google" />
      </div>
    </>
  );
};

export default PaymentMethods;
