import React from 'react'
import { Link } from 'gatsby'
import Icon from '../../atoms/Icon/Icon'

import Price from "../../molecules/Price/Price"
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton'

import styles from './ProductTab.module.css'

const ProductTabTemplate = ({data, addToCart, additionalAddAction, removeFunction}) => {
    const getLink = () => {
        const prepend = ''; // '/product';
        const append = '';

        // BC API generated
        if ('custom_url' in data) return `${prepend}${data.custom_url.url}${append}`;
        // BC GraphQL
        else if ('path' in data) return `${prepend}${data.path}${append}`;
        // Not found
        else return '#';
    }

    const getImage = () => {
        // BC API generated
        if (data.images && Array.isArray(data.images) && 'url_thumbnail' in data.images[0]) return data.images.filter(img => img.is_thumbnail)[0].url_thumbnail;
        // BC GraphQL
        else if (data.images && 'edges' in data.images) return data.images.edges.filter(img => img.node.is_thumbnail)[0].node.url;
        else if ('defaultImage' in data) return data.defaultImage.url;
        // Default image
        else return 'https://placehold.it/100x100?text=Not+Found';
    }

    const getVariantValue = (key) => {
        // BC API generated
        if (Array.isArray(data.variants) && key in data.variants[0]) return data.variants[0][key];
        // BC GraphQL
        else if ('edges' in data.variants && data.variants.edges.length > 0 && key in data.variants.edges[0].node) return data.variants.edges[0].node[key];
        else if (key === 'product_id') return data.entityId;
        // Default
        else return null;
    }

    const getPrices = () => {
        if ('prices' in data)
            return {
                price: data.prices.price ? data.prices.price.value : null, 
                calculated_price: data.prices.calculatedPrice ? data.prices.calculatedPrice.value : null,
                sale_price: data.prices.salePrice ? data.prices.salePrice.value : null,
                retail_price: data.prices.retailPrice ? data.prices.retailPrice.value : null,
            }

        else
            return {
                price: data.price,
                calculated_price: data.calculated_price,
                sale_price: data.sale_price,
            }

    }

    return (
        <div className={styles.productTab}>
            <div className={styles.imageContainer}>
                <Link to={getLink()}>
                    <img loading="lazy" src={getImage()} alt={data.name} />
                </Link>
            </div>
            <div className={styles.detailsContainer}>
                <Link to={getLink()}><h5>{data.name}</h5></Link>
                <Price styles={styles} entityId={data.entityId} {...getPrices()} />
            </div>
            <div className={styles.actionsContainer}>
                {data.variants && addToCart && (
                    <AddToCartButton
                        className={styles.actionItem}
                        productId={getVariantValue('product_id')}
                        variantId={getVariantValue('id')}
                        additionalAction={additionalAddAction}
                    >
                        <Icon symbol="bag" />
                    </AddToCartButton>
                )}
                {removeFunction && (
                    <span role="presentation" className={styles.actionItem} onClick={() => removeFunction()}>
                        <Icon symbol="close" />
                    </span>
                )}
            </div>
        </div>
    )
}
  
export default ProductTabTemplate