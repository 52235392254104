/**
 * Klaviyo API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { klaviyoApi } from '../helpers/klaviyo'

    klaviyoApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function klaviyoApi(endpoint, method, body) {
  const options = {
    method: method ? method : 'GET'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }
    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  return await fetch(
    `/.netlify/functions/klaviyo?endpoint=${btoa(endpoint)}`,
    options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

function getCurrentEDTDateString() {
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * 60000;
  const edtOffset = -4 * 3600 * 1000; // EDT is UTC-4
  const edtTime = new Date(utc + edtOffset);

  // Format to 'YYYY-MM-DDTHH:MM:SS-0400'
  return (
    edtTime.getFullYear() +
    '-' +
    ('0' + (edtTime.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + edtTime.getDate()).slice(-2) +
    'T' +
    ('0' + edtTime.getHours()).slice(-2) +
    ':' +
    ('0' + edtTime.getMinutes()).slice(-2) +
    ':' +
    ('0' + edtTime.getSeconds()).slice(-2) +
    '-0400'
  );
}

// console.log('Current EDT Time: ', getCurrentEDTDateString());

/**
 * Get all Klaviyo lists
 * 
 * Output list of mailing lists
 *  
 * @param   {String} email          The email to search on
 * 
 * @return  {array}                 List of mailing lists
 *
    import { getLists } from '../helpers/klaviyo'

    getLists();
 */
async function getLists() {
  return await klaviyoApi(`/lists/`);
}

/**
 * Get all Klaviyo lists by email
 * 
 * Output list of mailing lists an email is attached to
 *  
 * @param   {String} email          The email to search on
 * 
 * @return  {array}                 List of mailing lists
 *
    import { getListsByEmail } from '../helpers/klaviyo'

    getListsByEmail(email);
 */
async function getListsByEmail() {
  const {
    response: { data: lists }
  } = await getLists();

  // const subscriptions = await Promise.all(
  //   lists.map(async list => {
  //     const subscribersResponse = await klaviyoApi(
  //       `/lists/${list.id}/profiles?email=${encodeURIComponent(email)}`,
  //       'GET'
  //     );
  //     console.log(subscribersResponse, 'Subscribers for list', list.id);

  //     if (
  //       subscribersResponse.status !== 200 ||
  //       !Array.isArray(subscribersResponse.data)
  //     ) {
  //       console.error('Invalid response', list.id);
  //       return { list: list.attributes.name, subscribed: false };
  //     }

  //     const isSubscribed = subscribersResponse.data.some(
  //       profile => profile.attributes.email === email
  //     );
  //     return { list: list.attributes.name, subscribed: isSubscribed };
  //   })
  // );

  // return subscriptions.filter(sub => sub.subscribed);

  return lists;
}

/**
 * Subscribe form to list
 * 
 * Process subscription of an email to a defined list
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { subscribeForm } from '../helpers/klaviyo'

    subscribeForm(listId, fields);
 */
async function subscribeForm(listId, fields) {
  // console.log(fields);
  const { email, phone, first_name, last_name } = fields;
  const consentedAt = getCurrentEDTDateString();
  // console.log(consentedAt, 'date');
  const body = {
    data: {
      type: 'profile-subscription-bulk-create-job',
      attributes: {
        custom_source: 'Marketing Event',
        profiles: {
          data: [
            {
              type: 'profile',
              attributes: {
                email: email,
                phone_number: !phone ? '+15005550006' : `+61${phone}`,
                subscriptions: {
                  email: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                      consented_at: consentedAt
                    }
                  },
                  sms: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                      consented_at: consentedAt
                    }
                  }
                }
              }
            }
          ]
        }
      },
      relationships: { list: { data: { type: 'list', id: listId } } }
    }
  };

  // console.log('Attempting to subscribe with body:', body);

  return await klaviyoApi(
    `profile-subscription-bulk-create-jobs`,
    'POST',
    body
  );
}

/**
 * Subscribe email to list
 * 
 * Process subscription of an email to a defined list
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { subscribe } from '../helpers/klaviyo'

    subscribe(listId, email);
 */
async function subscribe(listId, email, phone) {
  const consentedAt = getCurrentEDTDateString();
  const body = {
    data: {
      type: 'profile-subscription-bulk-create-job',
      attributes: {
        custom_source: 'Marketing Event',
        profiles: {
          data: [
            {
              type: 'profile',
              attributes: {
                email: email,
                phone_number: !phone ? '+15005550006' : `+61${phone}`,
                subscriptions: {
                  email: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                      consented_at: consentedAt
                    }
                  },
                  sms: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                      consented_at: consentedAt
                    }
                  }
                }
              }
            }
          ]
        }
      },
      relationships: { list: { data: { type: 'list', id: listId } } }
    }
  };

  // console.log('Attempting to subscribe with body:', body);
  try {
    const response = await klaviyoApi(
      `profile-subscription-bulk-create-jobs`,
      'POST',
      body
    );
    // console.log(`Subscribed profile to list ${listId}`, response);
  } catch (error) {
    console.error('Error subscribing:', error);
    throw error;
  }
}

/**
 * Unsubscribe email from list
 * 
 * Process subscription removal of an email from a defined list
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { unsubscribe } from '../helpers/klaviyo'

    unsubscribe(listId, email);
 */
async function unsubscribe(listId, email, phone) {
  try {
    const body = {
      data: {
        type: 'profile-subscription-bulk-delete-job',
        attributes: {
          profiles: {
            data: [
              {
                type: 'profile',
                attributes: {
                  email: email,
                  phone_number: !phone ? '+10000000000' : `+61${phone}`
                }
              }
            ]
          }
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: `${listId}`
            }
          }
        }
      }
    };
    await klaviyoApi('/profile-subscription-bulk-delete-jobs/', 'POST', body);
    // console.log(
    //   `Unsubscribed profile to list ${listId},email : ${email}, phone: ${phone}`
    // );
  } catch (error) {
    console.error('Error subscribing:', error);
    throw error;
  }
}

export {
  klaviyoApi,
  getLists,
  getListsByEmail,
  subscribeForm,
  subscribe,
  unsubscribe
};
