import React, { useContext } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import CartContext from '../../../context/CartProvider';
// import AuthContext from '../../../context/AuthProvider';
import Button from '../../atoms/Button/Button';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import CouponForm from './CouponForm';
import ShippingInfo from './ShippingInfo';
// import GiftCertificate from './GiftCertificate';
import Donation from './Donation';
import styles from './CartSummary.module.css';


const CartSummary = ({ cartType, onClick }) => {
  const ctx = useContext(CartContext);
  const cart = get(ctx, 'state.cart', {});
  const { currency, cartAmount, numberItems, taxAmount } = cart;

  // console.log(cartAmount, cartAmount / 1.1);

  if (!numberItems) {
    return null;
  }

  if (cartType === 'full') {
    return (
      <div className={styles.bcCartSummary}>
        <p className={styles.bcCartSummaryTitle}>Order summary</p>
        <div className={styles.bcCartSummaryItemsContainer}>
          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}>Subtotal</span>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter currency={currency.code} amount={cartAmount} ignoreTax={true} />
            </span>
          </div>

          <div className={styles.bcCartSummaryItem}>
            <ShippingInfo />
          </div>

          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}>GST</span>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                currency={currency.code}
                amount={taxAmount} // GST value not provided in cart
                ignoreTax={true}
              />
            </span>
          </div>

          {/* Coupon Codes */}
          <div className={styles.bcCartSummaryItem}>
            <CouponForm />
          </div>

          {/* Gift Certificate */}
          {/* <div className={styles.bcCartSummaryItem}>
            <GiftCertificate />
          </div> */}


          {/* Donation */}
          <Donation />
        </div>

        <div className={clsx('flex-between', styles.bcCartSummaryItemTotal)}>
          <span className={styles.bcCartSummaryItemTotalLabel}>
            Grand Total
          </span>
          <span className={styles.bcCartSummaryItemTotalLabel}>
            <CurrencyFormatter currency={currency.code} amount={cartAmount} ignoreTax={true} />
          </span>
        </div>

        <div className={clsx('flex-end', styles.bcCartCheckoutButton)}>
          <Button level="primary" href="/checkout">
            Proceed to Checkout
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.bcMiniCartSummary}>
      {/* Donation */}
      <Donation />

      <div data-mini-subtotal className="flex-between">
        <span>Subtotal</span>
        <span>
          <CurrencyFormatter currency={currency.code} amount={cartAmount} ignoreTax={true} />
        </span>
      </div>
      <div className={styles.buttonWrapp}>
        <Button level="primary" href="/cart" onClick={onClick}>
          <span>View Cart</span>
        </Button>
        <Button level="secondary" href="/checkout">
          Checkout
      </Button>
      </div>
    </div>
  );
};

export default CartSummary;
