import React, { useEffect, useState } from 'react';

import styles from './CountdownTimer.module.css';

const ExpiredNotice = ({bannerId}) => {
  const banner = document.getElementById(bannerId);
  document.body.removeAttribute('style');

  if (banner) {
    banner.style.display = 'none';
  }

  return (
    <div className="expired-notice">
      <span>Expired!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();
  
    const [countDown, setCountDown] = useState(
      countDownDate - new Date().getTime()
    );
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);
  
      return () => clearInterval(interval);
    }, [countDownDate]);
  
    return getReturnValues(countDown);
};
  
const getReturnValues = (countDown) => {
// calculate time left
const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
const hours =  Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

return [days, hours, minutes, seconds];
};

const ShowCounter = ({days, hours, minutes, seconds, color}) => {
  return (
    <div className={`${styles.bannerCountdown}`} style={{color: color}}>
        <div className={`${styles.countColumn}`}>
            <div className={`${styles.countdownLabel}`}>DAYS</div>
             <div className={`${styles.countDownDays}`}>{days < 10 ? '0' + days : days}</div>
        </div>
        <div>
            <div className={`${styles.countdownLabel}`}>&nbsp;</div>
            <div className={`${styles.countdownRatio}`}>:</div>
        </div>
        <div className={`${styles.countColumn}`}>
            <div className={`${styles.countdownLabel}`}>HOURS</div>
             <div className={`${styles.countDownHours}`}>{hours < 10 ? '0' + hours : hours}</div>
        </div>
        <div>
            <div className={`${styles.countdownLabel}`}>&nbsp;</div>
            <div className={`${styles.countdownRatio}`}>:</div>
        </div>
        <div className={`${styles.countColumn}`}>
            <div className={`${styles.countdownLabel}`}>MINUTES</div>
            <div className={`${styles.countDownMinutes}`}>{minutes < 10 ? '0' + minutes : minutes}</div>
        </div>
        <div>
            <div className={`${styles.countdownLabel}`}>&nbsp;</div>
            <div className={`${styles.countdownRatio}`}>:</div>
        </div>
        <div className={`${styles.countColumn}`}>
            <div className={`${styles.countdownLabel}`}>SECONDS</div>
            <div className={`${styles.countDownSeconds}`}>{seconds < 10 ? '0' + seconds : seconds}</div>
        </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate, color, bannerId}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice bannerId={bannerId} />;
  } else {

    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        color={color}
      />
    );
  }
};

export default CountdownTimer;